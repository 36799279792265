
export const headerLab = {
  logo: {
    img: null,
  },
  links: [
    {
      label: "Home",
      link: "/",
    },
    {
      label: "Blog",
      link: "https://blog.cavepot.com/",
    },
    {
      label: "Our Company",
      link: "https://cavepot.com",
    },
    {
      label: "Contact us",
      link: "https://cavepot.com/contact",
    },
  ],
  backgroundColor: "white",
  textColor: "black",
  contactBtnColor: "#e5405d",
  contactBtnColorText: "white"
};
